export function showNotification(title, message, duration) {
  $(".custom-toast").remove();
  // Создаем уведомление динамически
  var notification = $(`
    <div class="custom-toast d-flex justify-content-center align-items-center" aria-live="polite" aria-atomic="true" data-autohide="true">
      <div class="toast show fade" role="alert" aria-live="assertive" aria-atomic="true" data-delay="${duration}">
        <div class="toast-header">
          <strong class="mr-auto">${title}</strong>
          <button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="toast-body">
          ${message}
        </div>
      </div>
    </div>
  `);

  // Вставляем уведомление после тега header
  $("header").after(notification);

  // Показываем уведомление
  notification.find(".toast").toast("show");
}
