export const catalogMobileFilterForm = (items) => {
  let form = $("#CatalogMobileFilterForm");

  if (form.length) {
    form.find("input").change(function (e) {
      let [min, max] = range.noUiSlider.get();

      form.find('input[name=price_range_min]').first().val(parseFloat(min));
      form.find('input[name=price_range_max]').first().val(parseFloat(max));

      form.submit();
    });
  }
};
