let sideBarCartFooter = (total) => `
  <div class="card-footer mt-auto border-0 bg-transparent px-6 pb-0 pt-5">
    <div class="d-flex align-items-center mb-2">
      <span class="text-secondary fs-15">Total price:</span>
      <span class="d-block ml-auto fs-24 font-weight-bold text-secondary">${sessionCurrencySymbol}${total}</span>
    </div>
    <a href="/cart" class="btn btn-outline-secondary btn-block">View Cart</a>
  </div>
  `;

let sideBarCardRow = (item) => `
  <div class="mb-4 d-flex">
    <a href="${item._links.destroy}" class="d-flex align-items-center mr-2 text-muted"><i class="fal fa-times"></i></a>
    <div class="media w-100">
      <div class="w-60px mr-3">
        <img src="${item.img}"
          alt="${item.product.name}">
      </div>
      <div class="media-body d-flex">
        <div class="cart-price pr-6">
          <p class="fs-14 font-weight-bold text-secondary mb-1">
            ${item.priceToShow}
          </p>
          ${item.pack}
          <a href="${item._links.show}"
            class="text-secondary">${item.product.name}</a>
        </div>
        <div class="position-relative ml-auto" style="min-width:40px">
          ${item.quantity} ${item.quantity_postfix}
        </div>
      </div>
    </div>
  </div>
  `;

export const updateSideBarCard = (items) => {
  let cardCount = $("#cardCount");
  cardCount.text(items.length);

  let card = $("#sideBarCard");
  card.empty();
  items.forEach(function (item) {
    if (item.free) {
      item.priceToShow = '<span class="text-success">FREE</span>';
    } else {
      item.priceToShow = sessionCurrencySymbol + item.price;
    }
    if (item.pack > 1) {
      item.pack = item.pack + " x";
    } else {
      item.pack = "";
    }
    card.append(sideBarCardRow(item));
  });

  card.parent().find(".card-footer").first().remove();

  card
    .parent()
    .append(
      sideBarCartFooter(
        parseFloat(
          items.reduce(
            (sum, item) => parseFloat(sum) + parseFloat(item.price),
            0
          )
        ).toFixed(2)
      )
    );
};
