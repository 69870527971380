export const supportChat = () => {
  let messagesBox = $('#messagesContainer');
  let messageForm = $("#messageForm");
  let messageFormInputs = $("#messageForm :input");
  let firstInit = true;

  function csrf() {
    return document.querySelector('meta[name="csrf-token"]').content;
  }

  messageForm.on("submit", async function (e) {
    e.preventDefault();

    if ($("#message").val().length === 0) {
      $("#message").addClass('is-invalid');
      return;
    } else {
      $("#message").removeClass('is-invalid');
    }

    messageFormInputs.toggleClass('disabled');
    messageFormInputs.prop('disabled', true);

    if (!window.supportChat.conversationId) {
      await createConversation();
      return;
    }

    $.ajax({
      type: "POST",
      url: "/support-chat/messages/store",
      data: {
        message: $("#message").val(),
        _token: csrf()
      },
      cache: false,
      success: function (response) {
        $("#message").val("");
        renderMessage(response.data);
        messageFormInputs.toggleClass('disabled');
        messageFormInputs.prop('disabled', false);
      },
    });
  });

  function renderMessage(message, prepend = false, scroll = true) {
    let rendered = '';
    if (message.is_sender === 0) {
      rendered = `<div class="message-wrapper"><div class="profile-picture"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg></div><div class="message-content"><p class="name">${message.sender.name}</p><div class="message">` +
        message.body +
        "</div></div></div>";
    } else {
      rendered = `<div class="message-wrapper reverse"><div class="profile-picture"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg></div><div class="message-content"><p class="name">${message.sender.name}</p><div class="message">` +
        message.body +
        "</div></div></div>"
    }

    if (prepend) {
      messagesBox.prepend(rendered);
    } else {
      messagesBox.append(rendered);
    }

    if (scroll === true) {
      scrollDownMessageBox();
    }
  }

  function subscribeConversation(conversationId) {
    const waitEcho = setInterval(function () {
      if (window.Echo) {
        window.Echo.private(window.broadcasting.channel).listen(
          "conversation-new-message",
          (response) => {
            // вынести подгрузку непрочитанных для переизспользования
            // незабудь авто пагинацию
            loadNewMessage(response.message_id);
          }
        );

        clearInterval(waitEcho);
      }
    }, 1000);
  }

  async function createConversation() {
    if (!window.supportChat.conversationId) {
      await $.ajax({
        type: "POST",
        url: "/support-chat/messages/store",
        data: {
          message: $("#message").val(),
          _token: csrf()
        },
        cache: false,
        success: function (response) {
          $("#message").val("");
          subscribeConversation(response.data.conversation_id);
          renderMessage(response.data);
          window.supportChat.conversationId = response.data.conversation_id;
          messageFormInputs.toggleClass('disabled');
          messageFormInputs.prop('disabled', false);
        },
      });
    }
  }

  function loadNewMessage(id) {
    $.ajax({
      type: "GET",
      url: `/support-chat/messages/${id}`,
      cache: false,
      success: function (response) {
        renderMessage(response.data);
      },
    });
  }

  $(".close-button").on("click", function () {
    $(".chat-container").css("display", "none");
    $(".chat-button").css("display", "block");
  });
  $(".chat-button").on("click", function () {
    $("#chatContactContainer").css("display", "none");
    $("#messagesContainer").css("display", "block");
    $("#inputContainer").css("display", "block");
    $(".chat-button").css("display", "none");
    $(".chat-container").css("display", "flex");
    scrollDownMessageBox();
  });

  let currentPage = 1;
  let noMoreMessages = false;
  let loadingMessages = false;
  function loadMessages(scroll = false) {
    if (
      noMoreMessages === false
      && loadingMessages === false
    ) {
      loadingMessages = true;
      $.ajax({
        type: "GET",
        url: `/support-chat/messages?page=${currentPage}`,
        cache: false,
        success: function (response) {
          let current_top_element = messagesBox.children().first();
          for (let i = 0; i < response.data.length; i++) {
            renderMessage(response.data[i], true, false);
          }

          let previous_height = 0;
          current_top_element.prevAll().each(function() {
            previous_height += $(this).outerHeight();
          });

          messagesBox.scrollTop(previous_height);
          if (response.data.length < 20) {
            noMoreMessages = true;
          }
          loadingMessages = false;
          currentPage++;
        },
      });
    }
  }

  messagesBox.on('scroll', function (e) {
    if (
      $(this).scrollTop() === 0
      && noMoreMessages === false
    ) {
      loadMessages();
    }
  });


  function scrollDownMessageBox() {
    messagesBox
      .finish()
      .animate(
        {
          scrollTop: messagesBox.prop("scrollHeight"),
        },
        250
      );
  }

  if (window.supportChat.conversationId) {
    firstInit = false;
    $('#GuestChatHelloMessage').remove();
    loadMessages(true);
    subscribeConversation(window.supportChat.conversationId);
  }
};
