export const switchVisibleFiltersPopup = () => {
  const toggleButton = $(".mobile-filter-btn");
  const filtersPopup = $(".filters-popup");
  const closeButton = filtersPopup.find(".close-btn");

  toggleButton.on("click", function () {
    const id = $(this).attr("data-target");
    const currentPopup = $(`.filters-popup[data-id='${id}']`);

    toggleButton.not($(this)).removeClass("opened");
    $(this).toggleClass("opened");
    filtersPopup.not(currentPopup).slideUp();

    if (currentPopup.length) {
      currentPopup.slideToggle();
    }
  });

  closeButton.on("click", function () {
    $(this).parents(".filters-popup").slideUp();
    toggleButton.removeClass("opened");
  });
};
