export const catalogDesktopFilterForm = (items) => {
  let form = $("#CatalogDesktopFilterForm");

  if (form.length) {
    let range = document.getElementById('range');

    let submit = function () {
      let [min, max] = range.noUiSlider.get();

      form.find('input[name=price_range_min]').first().val(parseFloat(min));
      form.find('input[name=price_range_max]').first().val(parseFloat(max));

      form.submit();
    }

    range.noUiSlider.on('change', function(values, handle) {
      submit();
    });

    form.find("select").change(function (e) {
      submit();
    });
  }
};
