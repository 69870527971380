export const focusOnReviews = () => {
  $("#seeReviews").on("click", function (e) {
    e.preventDefault();
    $("html, body").animate(
      {
        scrollTop: $("#tabs").offset().top,
      },
      600
    );
    $("#pills-reviews-tab").tab("show");
    $("#evaluatorName").focus();
  });
};
