export const filterRadioButtons = (containerSelector) => {
  $(containerSelector).each(function () {
    const container = $(this);
    const input = container.find('input[type="text"]');
    const radioLabels = container.find(".checkbox-label");

    input.on("input", function () {
      const searchQuery = $(this).val().toLowerCase();

      radioLabels.each(function () {
        const labelText = $(this).find("p").text().toLowerCase();

        if (labelText.includes(searchQuery)) {
          $(this).show(); // Показываем совпадающие радио-кнопки
        } else {
          $(this).hide(); // Скрываем те, что не соответствуют поиску
        }
      });
    });
  });
};
