export function productItemHover() {
  const productItemWrap = document.querySelectorAll(".card-item-wrap");

  function handleResize() {
    if (window.innerWidth >= 992) {
      // Включение кода при ширине окна 992px и выше

      productItemWrap.forEach(function (item, index) {
        const thisItemHeight = item.querySelector(".card-item").offsetHeight;
        const thisHidedBlockHeight =
          item.querySelector(".card-form").offsetHeight;

        item.style.height = thisItemHeight - thisHidedBlockHeight + "px";
        item.style.zIndex = productItemWrap.length - index;
        item.querySelector(".card-item").classList.add("isAbs");
      });
    } else {
      // Выключение кода при ширине окна менее 992px
      productItemWrap.forEach(function (item) {
        item.style.height = "100%"; // Возвращение к автоматической высоте
        item.querySelector(".card-item").classList.remove("isAbs");
      });
    }
  }

  // Вызываем функцию при загрузке страницы
  handleResize();

  // Добавляем обработчик события resize для отслеживания изменения размера окна
  window.addEventListener("resize", handleResize);
}
