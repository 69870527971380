export const initFiltersSelect = () => {
  const selects = $(".js-select");
  const selectWithoutSearch = $("#selectWithoutSearch");

  if (window.innerWidth >= 768) {
    selects.each(function () {
      const selectElement = $(this);

      const liveSearch = selectElement.is(selectWithoutSearch) ? false : true;

      selectElement.selectpicker({
        actionsBox: true,
        liveSearch: liveSearch,
        showIcon: true,
        width: "100%",
        size: "auto",
        // windowPadding: [10, 15, 20, 30],
      });
      selects.show();
    });
  } else {
    selects.selectpicker("destroy");
    selects.hide();
  }
};
