const filteredWizardProductsWrapper = `
  <div class="col-12">
    <div class="slick-slider mx-n2"
      data-slick-options='{"slidesToShow": 4,"dots":false,"arrows":true, "adaptiveHeight": true, "responsive":[{"breakpoint": 1600, "settings": {"arrows": false}},{"breakpoint": 1440, "settings": {"dots": true,"slidesToShow": 3}},{"breakpoint": 1200,"settings": {"slidesToShow":2,"arrows":false,"dots":true}},{"breakpoint": 992,"settings": {"slidesToShow":2,"arrows":false,"dots":true}},{"breakpoint": 768,"settings": {"slidesToShow": 2,"arrows":false,"dots":true}},{"breakpoint": 576,"settings": {"slidesToShow": 1,"arrows":false,"dots":true}}]}'>
    </div>
  </div>
    `;

export const homeWizardWatcher = () => {
  let form = $("#WizardHome").find("#WizardForm").first();
  let viewBtn = $("#WizardViewAllBtn");

  if (form) {
    let wizardGoalSelect = form.find("select[name=goal]");
    let wizardProductTypeRadio = form.find(
      "input[type=radio][name=product_sets]"
    );
    let wizardCategoryInput = form.find("input[name=category]");
    form.find("input, select").change(function (e) {
      e.preventDefault();

      if (wizardProductTypeRadio.val() === 0) {
        if (
          !productGoals.filter((item) => item.id === wizardGoalSelect.val())
            .length
        ) {
          wizardGoalSelect.val("");
        }
      } else if (wizardProductTypeRadio.val() === 1) {
        if (
          !setGoals.filter((item) => item.id === wizardGoalSelect.val()).length
        ) {
          wizardGoalSelect.val("");
        }
      }

      $("#WizardProductsBody").empty();
      $("#WizardProductsBody").append(`<div class="col-12 py-6">
          <p class="text-center fs-24 mb-0 text-uppercase">loading</p>
        </div>`);
      $.ajax({
        url: "api/wizardFiltered",
        dataType: "json",
        data: form.serialize(),
        success: function (response) {
          $("#WizardProductsBody").empty();
          if (response.filteredSets) {
            let body = $("#WizardProductsBody");
            response.filteredSets.forEach((html) => body.append(html));
            body.find(".set-slider").slick({
              slidesToShow: 3,
              slidesToScroll: 1,
              dots: false,
              adaptiveHeight: true,
              infinite: false,
              arrows: true,
              prevArrow:
                '<div class="set-prev"><i class="far fa-chevron-left"></i></div>',
              nextArrow:
                '<div class="set-next"><i class="far fa-chevron-right"></i></div>',
              responsive: [
                {
                  breakpoint: 1199,
                  settings: "unslick",
                },
              ],
            });
            viewBtn.attr("href", "/catalogue?" + form.serialize());
          } else if (response.filteredProducts) {
            $("#WizardProductsBody").append(filteredWizardProductsWrapper);
            let body = $("#WizardProductsBody").find(".slick-slider").first();
            response.filteredProducts.forEach((html) =>
              body.append('<div class="box">' + html + "</div>")
            );
            APP.slickSlider.init(body);
            viewBtn.attr("href", "/catalogue?" + form.serialize());
          } else {
            $("#WizardProductsBody").append(`<div class="col-12 py-6">
          <p class="text-center fs-24 mb-0 text-uppercase">no data on your request</p>
        </div>`);
          }
        },
      });
    });
  }
};
