export function limitTextOfPostsInTabs() {
  const tabsText = $(".tabs-text");

  tabsText.each(function () {
    $(this)
      .children()
      .each(function (index, element) {
        if (index === 1) {
          // Добавляем "etc." ко второму дочернему элементу
          $(element).text($(element).text() + " etc.");
        } else if (index >= 2) {
          $(element).remove(); // Удаляем все элементы, начиная с третьего
        }
      });
  });
}
