import { checkoutSubmit } from "./checkoutSubmit";

export const validateCheckoutPhoneInput = () => {
  const form = document.getElementById("checkoutValidate");
  const input = document.getElementById("acc-phone");
  const submitButton = $("#checkoutSubmit");

  // Проверяем, существуют ли необходимые элементы
  if (!form || !input || submitButton.length === 0) {
    console.warn("Required DOM elements are not found.");
    return; // Прекращаем выполнение функции, если элементы не найдены
  }

  input.addEventListener("input", function (e) {
    this.value = this.value.replace(/[^0-9+]/g, "");

    // Проверка валидности формы сразу после ввода
    if (form.checkValidity()) {
      form.classList.add("was-validated");
      submitButton.prop("disabled", false);
    } else {
      form.classList.remove("was-validated");
      submitButton.prop("disabled", true);
    }
  });

  form.addEventListener("submit", function (e) {
    if (!form.checkValidity()) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      checkoutSubmit();
    }

    form.classList.add("was-validated");
  });
};
