import "./bootstrap";
import { supportChat } from "./modules/supportChat";
import { changeEmailFormWatcher } from "./modules/changeEmailFormWatcher";
import { homeWizardWatcher } from "./modules/homeWizardWathcer";
import { productCardBuyActionWatcher } from "./modules/productCardBuyActionWatcher";
// import { switchHideShowWizardBlock } from "./modules/switchHideShowWizardBlock";
// import { socialDropUp } from "./modules/socialDropUp";
import { hideReleaseForm } from "./modules/hideReleaseForm";
import { switchActiveGoalBtn } from "./modules/switchActiveGoalBtn";
import { setRightColScroll } from "./modules/setRightColScroll";
import { enableSetSLider } from "./modules/enableSetSLider";
import { hideMenuFilters } from "./modules/hideMenuFilters";
import { initNewsFeedSlider } from "./modules/initNewsFeedSlider";
import { productItemHover } from "./modules/productItemHover";
import { limitTextOfPostsInTabs } from "./modules/limitTextOfPostsInTabs";
import { focusOnReviews } from "./modules/focusOnReviews";
import { setHeightToTitle } from "./modules/setHeightToTitle";
import { showPaymentForm } from "./modules/showPaymentForm";
import { copyToClipboard } from "./modules/copyToClipboard";
import { chatHeightChange } from "./modules/chatHeightChange";
import { searchPopupInit } from "./modules/searchPopupInit";
import { initFiltersSelect } from "./modules/initFiltersSelect";
import { initPriceRange } from "./modules/initPriceRange";
import { switchHideShowFilters } from "./modules/switchHideShowFilters";
import { filterRadioButtons } from "./modules/filterRadioButtons";
import { switchVisibleFiltersPopup } from "./modules/switchVisibleFiltersPopup";
import { catalogDesktopFilterForm } from "./modules/catalogDesktopFilterForm";
import { catalogMobileFilterForm } from "./modules/catalogMobileFilterForm";
import { validateCheckoutPhoneInput } from "./modules/validateCheckoutPhoneInput";
import { changeElementsPosition } from "./modules/changeElementsPosition";

$(window).on("resize", function () {
  if ($(window).width() >= 1200) {
    enableSetSLider();
  }

  initFiltersSelect();
  // setHeightToTitle();
});

$(".clear-btn").on("click", function () {
  window.location.replace(window.location.origin + window.location.pathname);
});

$(document).on("load", function () {
  // productItemHover();
});

$(document).ready(function () {
  // switchHideShowWizardBlock();
  switchActiveGoalBtn();
  setRightColScroll();
  hideMenuFilters();
  enableSetSLider();
  productCardBuyActionWatcher();
  hideReleaseForm();
  homeWizardWatcher();
  limitTextOfPostsInTabs();
  initNewsFeedSlider();
  focusOnReviews();
  changeEmailFormWatcher();
  productItemHover();
  // checkoutSubmit();
  showPaymentForm();
  copyToClipboard();
  supportChat();
  // socialDropUp();
  chatHeightChange();
  setHeightToTitle();
  searchPopupInit();
  initFiltersSelect();
  initPriceRange();
  switchHideShowFilters();
  filterRadioButtons(".filters-popup");
  switchVisibleFiltersPopup();
  catalogDesktopFilterForm();
  catalogMobileFilterForm();
  validateCheckoutPhoneInput();
  changeElementsPosition();
});
