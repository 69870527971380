export const copyToClipboard = () => {
  let isCopying = false; // Флаг состояния

  $("#clipboardBtn").click(async function () {
    if (isCopying) return; // Если уже выполняется копирование, выходим из функции

    isCopying = true; // Устанавливаем флаг

    // Текст для копирования
    const textCopy = $("#textToCopy").text();

    try {
      // Пытаемся использовать Clipboard API для копирования текста
      await navigator.clipboard.writeText(textCopy);

      // Заменяем иконку на текст "Copied" с иконкой Font Awesome
      let $button = $("#clipboardBtn");
      let originalContent = $button.html();
      $button.html("<i class='fal fa-check'></i> Copied");

      setTimeout(function () {
        $button.html(originalContent);
        isCopying = false; // Сбрасываем флаг после завершения операции
      }, 2000);
    } catch (err) {
      console.error("Failed to copy text: ", err);
      isCopying = false; // Сбрасываем флаг в случае ошибки
    }
  });
};
