export const initNewsFeedSlider = () => {
  const swiperSLiders = document.querySelectorAll(".news-feed__slider");
  const swiperNext = document.querySelectorAll(".swiper-button-next");
  const swiperPrev = document.querySelectorAll(".swiper-button-prev");

  swiperSLiders.forEach((item, i) => {
    new Swiper(item, {
      direction: "horizontal",
      slidesPerView: 1.5,
      freeMode: true,

      navigation: {
        nextEl: swiperNext[i],
        prevEl: swiperPrev[i],
      },
      observer: true,
      observerParents: true,
      spaceBetween: 15,

      breakpoints: {
        576: {
          slidesPerView: 2,
          freeMode: false,
          spaceBetween: 30,
        },
        992: {
          slidesPerView: 3,
          freeMode: false,
        },
        1200: {
          slidesPerView: 3,
          freeMode: false,
        },
        1440: {
          slidesPerView: 4,
          freeMode: false,
        },
      },
    });
  });
};
