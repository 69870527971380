import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

export default (options) => {
  if (window.Echo) {
    return window.Echo;
  }

  try {
    if (options.driver === 'pusher') {
      /**
       *-------------------------------------------------------------
       * Pusher initialization
       *-------------------------------------------------------------
       */
      Pusher.logToConsole = options.pusher.debug;
      window.Pusher = new Pusher(options.pusher.key, options.pusher.options);

      /**
       *-------------------------------------------------------------
       * Echo initialization
       *-------------------------------------------------------------
       */
      window.Echo = new Echo({
        broadcaster: 'pusher',
        key: options.pusher.key,
        client: window.Pusher,
        namespace: '',
      });
    }
  } catch (error) {
    console.log(error);
  }

  if (window.Echo) {
    return window.Echo;
  } else {
    throw {}; //todo фронт: единая система исключений для фронта
  }
};
