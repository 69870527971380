export function setRightColScroll() {
  if (
    window.matchMedia("(max-width: 1199px)").matches &&
    window.matchMedia("(min-width: 768px)").matches
  ) {
    let lastScroll = 0;
    window.onscroll = function () {
      if (window.scrollY > lastScroll) {
        $(".set-form").css("top", "10px");
        // console.log('down');
      } else {
        $(".set-form").css("top", "160px");
        // console.log('up');
      }
      lastScroll = window.scrollY;
    };
  }
}
