export const hideReleaseForm = () => {
  const releaseForm = $("#releaseForm");
  let select = $("#WizardForm").find("select[name=goal]");
  let wizardCategoryInput = $("#WizardForm").find("input[name=category]");

  $("#WizardProductSetsRadio").on("click", function () {
    wizardCategoryInput.attr("disabled", true);
    releaseForm.hide();
    select.empty();
    select.append('<option value="" selected>All goals</option>');
    setGoals.forEach(function (item) {
      select.append(`<option value="${item.id}">${item.name}</option>`);
    });
  });

  $("#WizardSingleProductsRadio").on("click", function () {
    wizardCategoryInput.attr("disabled", false);
    releaseForm.show();
    select.empty();
    select.append('<option value="" selected>All goals</option>');
    select.prop("selectedIndex", 0);
    productGoals.forEach(function (item) {
      select.append(`<option value="${item.id}">${item.name}</option>`);
    });
  });
};
