export class HideShowHelper {
  static hideShowBlockWithBtn = (btn, block) => {
    btn.click(function (e) {
      e.preventDefault();
      block.slideToggle();
      btn.parent().slideUp();
      console.log(block.find(".close"));

      if (block.find(".close")) {
        block.find(".close").click(function (e) {
          e.preventDefault();
          block.slideUp();
          btn.parent().slideDown();
        });
      }
    });
  };
}
