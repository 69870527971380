import qs from "query-string";

export const switchHideShowFilters = () => {
  const button = $("#filtersTrigger");
  const filtersContainer = $("#secondaryFilters");
  const parsedParams = qs.parse(window.location.search);
  const min = $("#range").attr("range-min");
  const max = $("#range").attr("range-max");

  const isOpen =
    Boolean(parsedParams?.substance) ||
    Boolean(parsedParams?.marker) ||
    Boolean(parsedParams?.goal);

  const isRangeChanged =
    (parsedParams?.price_range_min && parsedParams?.price_range_min !== min) ||
    (parsedParams?.price_range_max && parsedParams?.price_range_max !== max);

  const toggleFilters = () => {
    button.toggleClass("opened");
    filtersContainer.slideToggle();
  };

  button.on("click", toggleFilters);

  if (isOpen || isRangeChanged) {
    button.addClass("opened");
    filtersContainer.css("display", "block");
  }
};
