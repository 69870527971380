export const searchPopupInit = () => {
  $('a[href="#search-popup"').magnificPopup({
    type: "inline",
    focus: "#keyword",
    mainClass: "mfp-search-form mfp-move-from-top mfp-align-top",
    showCloseBtn: false,
  });
};

// export default searchPopupInit;
