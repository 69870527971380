export const chatHeightChange = () => {
  const chatWrapper = $(".right-side");
  chatWrapper.find(".chat-button").on("click", function () {
    chatWrapper.removeClass("h-auto");
    chatWrapper.addClass("h-100");
  });

  chatWrapper.find(".close-button").on("click", function () {
    chatWrapper.removeClass("h-100");
    chatWrapper.addClass("h-auto");
  });
};
