export const initPriceRange = () => {
  const range = document.getElementById("range");

  // console.log(
  //   parseFloat(range.attributes['range-min'].value),
  //   parseFloat(range.attributes['range-max'].value),
  //   range.attributes['current-min'].value,
  //   range.attributes['current-max'].value,
  //   range.attributes['current-min'].value !== '' ? parseFloat(range.attributes['current-min'].value) : parseFloat(range.attributes['range-min'].value),
  //   range.attributes['current-max'].value !== '' ? parseFloat(range.attributes['current-max'].value) : parseFloat(range.attributes['range-max'].value)
  // );
  if (range) {
    noUiSlider.create(range, {
      start: [
        range.attributes["current-min"].value !== ""
          ? parseFloat(range.attributes["current-min"].value)
          : parseFloat(range.attributes["range-min"].value),
        range.attributes["current-max"].value !== ""
          ? parseFloat(range.attributes["current-max"].value)
          : parseFloat(range.attributes["range-max"].value),
      ],
      connect: true,
      range: {
        min: parseFloat(range.attributes["range-min"].value),
        max: parseFloat(range.attributes["range-max"].value),
      },
      tooltips: [
        {
          to: function (value) {
            return "$" + Math.round(value); // Форматируем как целое число с $ символом
          },
          from: function (value) {
            return Number(value.replace("$", "")); // Удаляем $ для внутреннего использования
          },
        },
        {
          to: function (value) {
            return "$" + Math.round(value);
          },
          from: function (value) {
            return Number(value.replace("$", ""));
          },
        },
      ],
    });
  }
};
