import { MQ, md } from "../consts/breakpoints";

export const setHeightToTitle = () => {
  const item = $(".checkout-preview__item");
  const title = item.find(".checkout-preview__title");

  if (title.length === 0) return; // Если заголовков нет, выходим из функции

  MQ(
    md,
    function () {
      title.css("height", "auto");
    },
    function () {
      let maxHeight = 0;

      title.each((i, element) => {
        const elementHeight = $(element).height();
        if (elementHeight > maxHeight) {
          maxHeight = elementHeight;
        }
      });

      title.height(maxHeight);
    }
  );
};
