import { lg, MQ } from "../consts/breakpoints";

export const changeElementsPosition = () => {
  const desktopWrap = $(".add-product__inner");
  const price = $(".add-product__price");
  const name = $(".add-product__name");
  const mobileWrap = $(".add-product__mobile-wrap");

  MQ(
    lg,
    function () {
      mobileWrap.append(price, name);
    },
    function () {
      desktopWrap.prepend(price, name);
    }
  );
};
