import { showNotification } from "./showNotification";
import { updateSideBarCard } from "./updateSideBarCard";

export const productCardBuyActionWatcher = () => {
  $(".product-card-buy-action").submit(async function (e) {
    e.preventDefault();
    let btn = $(this).find(":submit");
    btn.text("");
    btn.append(
      '<img src="/images/rolling.svg" alt="spinner" style="width: 40px; margin: 0 auto;">'
    );
    btn.addClass("disabled");

    try {
      let res = await axios.get(
        $(this).attr("action") + "?" + $(this).serialize()
      );

      updateSideBarCard(res.data.user_card);
      showNotification("Added to cart", res.data.success, 3000);
    } catch (err) {
      if (err.response.status == 401) {
        showNotification("Error", "Not authorized", 3000);
        window.location.href = "/sign-in";
        return;
      }
      if (err.response.data.message) {
        showNotification("Error", err.response.data.message, 3000);
      } else if (err.response.data.warning) {
        showNotification("Error", err.response.data.warning, 3000);
      } else {
        showNotification("Error", "Something went wrong", 3000);
      }
      if (err.response.status == 403) {
        if (err.response.data.location) {
          window.location.href = err.response.data.location;
        }
      }
    } finally {
      btn.empty();
      btn.text("Add To Bag");
      btn.removeClass("disabled");
    }
  });
};
